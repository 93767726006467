/**
 * Helpers
 */
import { onVisibilityChange } from './helpers'
import Accordion from 'accordion-js';

/**
 * Web standards
 */
import './menu'
import './lazyload'
import './langswitcher'
import './cookieconsent'

/**
 * Scripts which doesn't need dynamic import
 */
import {  Modal } from 'bootstrap';

/**
 * Dynamic import scripts
 */
const elements = [
  '.reviews',
  '.reading-time',
  '#faq',
  '.downloads__categories',
  '.unsubscribe_newsletter',
  '.members-search'
];

[].forEach.call(elements, (element) => {


  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

    const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

      // Example library without init:
      if (element === '.reviews') {
        const Reviews = import('./reviews')
      }

      if (element === '.reading-time') {
        const ReadingTime = import('./reading_time')
      }
      
      if (element === '.downloads__categories') {
        const Categories = import('./downloads')
      }

      if (element === '.unsubscribe_newsletter') {
        const Unsubscribe = import('./unsubscribe')
      }

      if (element === '.members-search') {
        const MembersSearch = import('./members')
      }

      if (element === '#faq') {
        const acc = new Accordion('#faq', {
          duration: 300, 
          ariaEnabled: true, 
          collapse: true, 
          showMultiple: false, 
          openOnInit: [], 
          elementClass: 'block__faq__accordion__item', 
          triggerClass: 'block__faq__accordion__button', 
          panelClass: 'block__faq__accordion__panel', 
          activeClass: 'collapsed'
        });
      }
      
    })

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', lazyloadHandler, false);
      addEventListener('load', lazyloadHandler, false);
      addEventListener('scroll', lazyloadHandler, false);
      addEventListener('resize', lazyloadHandler, false);
    } else if (window.attachEvent) {
      attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
      attachEvent('onload', lazyloadHandler);
      attachEvent('onscroll', lazyloadHandler);
      attachEvent('onresize', lazyloadHandler);
    }
  }
});
